/* .view-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1rem 0;
} */

.view > canvas {
  background: gray !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.input-container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.nav-link:hover {
  background: lightblue !important;
}

.nav-link.active {
  background: lightgray !important;
}

/* #speedometer {
  width: 200px;
} */

.speedometer-label {
  display: block;
  margin-top: -2rem;
  text-align: center;
}

/* canvas#speedometer {
  width: 250px !important;
  height: 120px !important;
} */

/*.form-label {
  margin-right: 0.25rem;
} */
